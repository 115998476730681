import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const testimonialsCarousels = document.querySelectorAll(
    '.testimonials-carousel'
  );

  if (0 < testimonialsCarousels.length) {
    testimonialsCarousels.forEach(function (carousel) {
      new Swiper(carousel, {
        draggable: true,
        loop: true,
        slidesPerView: 'auto',
        autoHeight: true,

        a11y: {
          paginationBulletMessage: 'Aller au témoignage {{index}}',
          nextSlideMessage: 'Prochain témoignage',
          prevSlideMessage: 'Témoignage précédent',
        },
        navigation: {
          nextEl: carousel
            .closest('.testimonials-block')
            .querySelector(
              '.testimonials-carousel__navigation .swiper-button-next'
            ),
          prevEl: carousel
            .closest('.testimonials-block')
            .querySelector(
              '.testimonials-carousel__navigation .swiper-button-prev'
            ),
        },
        pagination: {
          el: carousel
            .closest('.testimonials-block')
            .querySelector(
              '.testimonials-carousel__navigation .swiper-pagination'
            ),
          type: 'fraction',
        },
      });
    });
  }
});
